/* Change Autocomplete styles in Chrome, Safari &  */

@-webkit-keyframes autofill {
    0%, 100% {
        color      : #FFFFFF !important;
        background : transparent !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    color                       : #FFFFFF !important;
    transition                  : background-color 5000000s ease-in-out 0s !important;
    background                  : transparent !important;
    -webkit-text-fill-color     : #FFFFFF !important;
    -webkit-animation-delay     : 1s !important; /* Safari support - any positive time runs instantly */
    -webkit-animation-name      : autofill !important;
    -webkit-animation-fill-mode : both !important;
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
    transition : opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity : 0;
}

/* Default Vuetify Overrides */

th[role="columnheader"] {
    white-space : nowrap;
}
